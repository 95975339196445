import Vue from 'vue'
import App from './App.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment'
import router from "@/router";
import BootstrapVue from 'bootstrap-vue'

import { store } from "@/store";
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
let socket = io('https://api.appyan7.com');
if (process.env.NODE_ENV != 'production') {
   socket = io('http://localhost:3000');
}
Vue.config.productionTip = false
Vue.use(VueSocketIOExt, socket);

Vue.filter("currency",(value) => {
  return parseFloat(value).toLocaleString(undefined, {minimumFractionDigits : 2})
});
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:MM')
  }
})
//styles
import './assets/css/style.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/css/bootstrap.min.css';

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2)

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
