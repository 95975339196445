<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      confirmMessage: this.$store.getters.confirmMessage,
      notifyMessage: this.$store.getters.notifyMessage,
      copy: this.$store.getters.copy,
    }
  },
  methods: {
    copyIt(){
      let input = document.createElement('textarea');
      input.innerHTML = this.copy.text;
      document.body.appendChild(input);
      input.select();
      let result = document.execCommand('copy');
      document.body.removeChild(input);
      this.sendNotify({icon: 'success', title: 'Kopyalandı!', message: ''})
      return result
    },
    sendConfirmMessage() {
      this.$swal({
        showConfirmButton: true,
        icon: this.confirmMessage.icon,
        title: this.confirmMessage.title,
        text: this.confirmMessage.message,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });
      this.$store.dispatch('getConfirmMessage', {show: false})
    },
    sendNotify(data) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: data.icon,
        title: data.title,
        text: data.message,
      });
    },
    sendNotifyMessageSite() {
      const sendIt = async (b) => {
        await this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: this.notifyMessage.icon,
          title: this.notifyMessage.title,
          text: this.notifyMessage.message,
        });
        b(true)
      }
      sendIt(async () => {
        await setTimeout(() => {
          this.$store.dispatch('getNotifyMessage', {show: false})
        }, 3100)
      })

    },
  },
  watch: {
    '$store.getters.copy': function() {
      if(this.$store.getters.copy.state)
      {
        this.copy = this.$store.getters.copy
        this.copyIt()
      }
    },
    '$store.getters.confirmMessage': function() {
      if(this.$store.getters.confirmMessage.show)
      {
        this.confirmMessage = this.$store.getters.confirmMessage
        this.sendConfirmMessage()
      }
    },
    '$store.getters.notifyMessage': function() {
      if(this.$store.getters.notifyMessage.show){
        this.sendNotifyMessageSite()
      }
    },
  }
}
</script>
