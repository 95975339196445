<template>
  <div>
    <div id="fullPageLoading">
      <div class="loading"></div>
      <br>
      <span>Aranma talebiniz alındı, lütfen bekleyiniz..</span>

    </div>

    <div class="container mt-5" >
      <div class="row">

        <div class="col-12 text-center mb-5">

          <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
              <div class="content-wrapper d-flex align-items-center auth px-0">
                <div class="row w-100 mx-0">
                  <div class="col-lg-6 col-sm-12 mx-auto">

                    <div class="auth-form-light text-left py-5 px-4 px-sm-5" id="wizardProfile" >

                      <div id="precall" style="display: block; margin-top: -20px;">
                        <div class="col-lg-6 col-sm-2 mx-auto" >

                          <a href="">
                            <img :src="require('../assets/images/logo.gif')" class="d-block mx-auto" style="margin-bottom:15px; " title=" Aranma Talep Sistemi"></a>

                        </div>
                        <center style="text-align: center">

                          <label>
                            Arama talepleriniz yoğunluk sırasına göre en kısa zamanda sonuçlanacaktır. Anlayışınız için teşekkür ederiz!</label>
                        </center>


                        <div id="gsmcode" class="" style="">

                          <div class="form-group">
                            <label >Kullanıcı Adınız : </label>
                            <input autocomplete="off" required type="text" class="form-control form-control-lg" v-model="username">
                          </div>

                          <div class="form-group">
                            <label >Temsilci Seçiniz : </label>
                            <div class="hiddenradio">
                              <p class="text-warning" v-if="personsLoading">Online temsilciler yükleniyor.. </p>
                              <p class="text-danger" v-if="!persons.length">Şuanda çevrimiçi temsilci bulunmamaktadır.</p>
                              <label v-for="(item, index) in persons" :key="index">
                                <input type="radio"  v-model="person_id" :id="item.id" :value="item.id">
                                <img class="gorselsec" :src="item.profile_pic">
                                <p style="margin-bottom: 0rem;text-align: center;">
                                  <i class="fa fa-circle" aria-hidden="true" style="color: #8BC34A;"></i>
                                  {{ item.name }}</p>
                              </label>
                            </div>
                          </div>


                          <div class="form-group">
                            <label >Adınız Soyadınız : </label>
                            <input autocomplete="off" required type="text" class="form-control form-control-lg"  placeholder="" v-model="name">
                          </div>
                            <div class="form-group">
                                <label >Güncel Telefon Numaranız : </label>
                                <input autocomplete="off" required type="number" class="form-control form-control-lg" v-model="phone" placeholder="555-123-45-67">
                            </div>

                            <div class="form-group">
                            <label >Aranma Nedeniniz : </label>
                            <textarea autocomplete="off" style="height: 70px !important;" required class="form-controlsir form-control-lg" v-model="user_info" placeholder="..."></textarea>
                          </div>
                          <div class="form-group">

                            <center>
                              <button type="submit"  class="wizard-footer" v-if="loading"> <i class="fa fa-spinner fa-spin"></i></button>
                              <button type="submit"  class="wizard-footer" v-else @click="request"> Talep Oluştur</button>
                            </center>



                          </div>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "Home",
  data() {
    return {
      loading: false,
      persons: [],
      personsLoading: false,
      name: '',
      username: '',
      phone: '',
      person_id: '',
      user_info: '',
      call_waiting: false,
    }
  },
  components: {   },
  sockets: {
    connect() {
      this.$socket.client.emit('call', {username: this.username})
    },
    call() {
      this.waitingRequests()
      this.lastRequest()
    }
  },
  methods: {
    getPersons() {
      this.personsLoading = true
      apiService.persons()
      .then((res) => {
        this.personsLoading = false
        if(res.state)
          this.persons = res.data
      })
    },
    request(){
      this.loading = true
      apiService.requests({username: this.username, phone: this.phone, user_info: this.user_info, person_id: this.person_id, name: this.name})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.call_waiting = true
        }else{
          this.waitingRequests()
          this.$store.dispatch('getConfirmMessage', {show:true, icon: 'error', title: 'Ops!', message: res.message})
        }
      })
    },
    waitingRequests() {
      apiService.waitingRequests({username: this.username})
      .then((res) => {
        if(res.state)
          this.call_waiting = true
      })
    },
    lastRequest(){
      apiService.lastRequest({username: this.username})
      .then((res) => {
        if(res.state){
          if(res.data[0].status === '1'){
            this.$store.dispatch('getConfirmMessage', {show:true, icon: 'success', title: 'Teşekkürler!', message: 'Aranma talebiniz müşteri temsilcimize ulaştı, en kısa sürede aranacaksınız.'})
          }else{
            this.$store.dispatch('getConfirmMessage', {show:true, icon: 'error', title: 'Ops!', message: res.data[0].answer})
          }
        }
      })
    },
  },
  created() {
    this.getPersons()
    this.waitingRequests()
  },
  watch: {
    call_waiting: function () {
      if(this.call_waiting){
        document.body.classList.add('page-loading')
      }else{
        document.body.classList.remove('page-loading')
      }
    }
  },
}
</script>

