import axios from 'axios';
let apiUrl = ''
if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://api.appyan9.com'
} else {
    apiUrl = 'http://localhost:3000'

   }

export default {
    persons(data) {
        return axios.get(apiUrl + '/call/persons', data)
            .then(response => response.data)
    },
    requests(data) {
        return axios.post(apiUrl + '/call/request', data)
            .then(response => response.data)
    },
    waitingRequests(data) {
        return axios.post(apiUrl + '/call/waiting-requests', data)
            .then(response => response.data)
    },
    lastRequest(data) {
        return axios.post(apiUrl + '/call/last-request', data)
            .then(response => response.data)
    },
}